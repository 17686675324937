<template>
  <div>
    <h1>シフト表ロック設定</h1>
    <p>指定日以前のシフト表を変更できないようロックします</p>
    <v-card>
      <v-card-actions class="ma-2">
        <DatePicker
          v-model="date"
          label="日選択"
          prepend-inner-icon="mdi-calendar"
          outlined
        />
        <v-spacer />
        <Btn
          icon="mdi-pencil"
          color="success"
          text
          outlined
          @click="updateDays"
          :disabled="!selected.length"
        >
          ロック設定
        </Btn>
        <Btn
          icon="mdi-eraser"
          color="error"
          text
          outlined
          @click="deleteDays"
          :disabled="!selected.length"
        >
          ロック削除
        </Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="簡易検索"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
        :item-key="itemKey"
        :items-per-page="-1"
        multi-sort
      >
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            @input="select($event)"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import ShiftLock from "@/models/ShiftLock";
const NOW = new Date();

export default {
  name: "ShiftLock",
  mixins: [Common, ShowDialogs, Api],
  props: { args: Object },
  data() {
    return {
      params: null,
      selected: [],
      search: "",
      open: true,
      title: "所属別設定",
      itemKey: "name",
      headers: [],
      items: [],
      date:
        NOW.getFullYear() +
        "-" +
        ("00" + Number(NOW.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + Number(NOW.getDate())).slice(-2)
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    }
  },
  methods: {
    async getData() {
      console.log("getData");
      const data = await this.$get(this.Paths.shiftLock);
      await this.setdata(data);
    },
    async setdata(data) {
      console.log("setdata");
      this.headers = data.headers;
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;
      this.selected = [];
    },
    async updateDays() {
      console.log("updateDays");
      const incodes = [];
      this.selected.forEach(s => {
        incodes.push(s.incode);
      });
      const param = new ShiftLock(incodes, this.date);

      this.$loading();

      try {
        await this.$post(this.Paths.shiftLock, param);
        await this.$info("更新しました。");
        await this.getData();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async deleteDays() {
      console.log("deleteDays");
      const incodes = [];
      this.selected.forEach(s => {
        incodes.push(s.incode);
      });
      const param = new ShiftLock(incodes, "");

      this.$loading();

      try {
        await this.$put(this.Paths.shiftLock, param);
        await this.$info("更新しました。");
        await this.getData();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    }
  },
  async created() {
    console.log("==created");
    this.$loading();
    try {
      await this.getData();
    } catch (e) {
      console.log(e);
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
<style>
.v-data-table td {
  background: #f0f8ff;
}
.v-data-table tr:nth-child(odd) td {
  background: #fff;
}
.v-data-table tr:hover td {
  background-color: #eee;
}
</style>
